import React from 'react'
import { MDBAlert } from 'mdb-react-ui-kit'

const FailAlert = () => {
  return (
    <MDBAlert
      position="bottom-right"
      defaultOpen
      autohide
      color="danger"
    >
      Handlingen feilet!
    </MDBAlert>
  );
}

export default FailAlert