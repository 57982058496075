import React, { useEffect, useState } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBSpinner,
} from "mdb-react-ui-kit";

const ModalStats = ({ facultyName, modalName, isOpen, onClose }) => {
  const [allData, setAllData] = useState([]);
  const [modalStats, setModalStats] = useState(null);
  const [loading, setLoading] = useState(true);
  

  // Fetch all-time statistics
  const fetchAllData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/getClickAndRatingStats.php`);
      const data = await response.json();
      console.log("All data fetched:", data); // Log the data to see what's fetched
      setAllData(data);
    } catch (error) {
      console.error("Error fetching all-time stats:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  // Aggregate statistics for the specific modalName and facultyName
useEffect(() => {
  const filteredStats = allData.filter(
    (stat) => stat.modalName === modalName && stat.facultyName === facultyName
  );
  const aggregatedStats = filteredStats.reduce(
    (acc, curr) => {
      acc.clickCount += parseInt(curr.clickCount, 10);
      acc.goodRatings += parseInt(curr.goodRatings, 10);
      acc.badRatings += parseInt(curr.badRatings, 10);
      acc.created_at = curr.created_at; // Keep the most recent date
      return acc;
    },
    { clickCount: 0, goodRatings: 0, badRatings: 0, created_at: "" } // Initialize created_at to avoid null
  );

  console.log("Aggregated Stats:", aggregatedStats);
  setModalStats(aggregatedStats);
}, [allData, modalName, facultyName]);


  return (
    <MDBModal open={isOpen} onClose={onClose} tabIndex="-1">
      <MDBModalDialog size="xl">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>
              {modalName} - All Time Statistics <br />{" "}
              <span>
                Publisert: {modalStats ? modalStats.created_at : "Loading..."}
              </span>
            </MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={onClose}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBContainer fluid>
              <MDBRow className="justify-content-center">
                <MDBCol md="12">
                  <section>
                    <MDBRow>
                      <MDBCol md="4" className="mb-md-0 mb-4">
                        <MDBCard>
                          <MDBCardBody>
                            <p className="mb-1">Total Clicks</p>
                            <h2 className="mb-0 text-primary">
                              {loading ? (
                                <MDBSpinner color="primary" role="status" />
                              ) : (
                                modalStats.clickCount
                              )}
                            </h2>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>

                      <MDBCol md="4" className="mb-md-0 mb-4">
                        <MDBCard>
                          <MDBCardBody>
                            <p className="mb-1">Good Ratings</p>
                            <h2 className="mb-0 text-primary">
                              {loading ? (
                                <MDBSpinner role="status" color="primary" />
                              ) : (
                                modalStats.goodRatings
                              )}
                            </h2>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>

                      <MDBCol md="4" className="mb-md-0 mb-4">
                        <MDBCard>
                          <MDBCardBody>
                            <p className="mb-1">Bad Ratings</p>
                            <h2 className="mb-0 text-primary">
                              {loading ? (
                                <MDBSpinner role="status" color="primary" />
                              ) : (
                                modalStats.badRatings
                              )}
                            </h2>
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                    </MDBRow>
                  </section>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={onClose}>
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default ModalStats;
