import React from 'react'
import { MDBSpinner, MDBTypography } from 'mdb-react-ui-kit';

const Loader = () => {
  return (
    <div className="loading m-auto d-flex flex-column align-items-center justify-content-center">
      <MDBSpinner
        grow
        color="primary"
        style={{ height: "7rem", width: "7rem" }}
      >
        <span className="visually-hidden">Loading...</span>
      </MDBSpinner>
      <MDBTypography tag="h3" className="text-center m-5 display-4">
        Laster ...
      </MDBTypography>
    </div>
  );
}

export default Loader