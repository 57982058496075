import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './scss/index.scss';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
            <App />
    </React.StrictMode>
);