import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Hero.css";
import axios from "axios";

const Hero = () => {
  const [faculties, setFaculties] = useState([]);

  useEffect(() => {
    // Fetch faculties data
    axios
      .get("/api/get_faculties.php")
      .then((response) => {
        setFaculties(response.data);
      })
      .catch((error) => {
        console.error("Error fetching faculties:", error);
      });
  }, []);

  return (
    <div className="nav_wrapper">
      <p>Velkommen! <br /></p>
      <h3>Velg din lokasjon</h3>
      <ul className="Navbar-links">
        {faculties.map((faculty) => (
          <li key={faculty.id}>
            <Link className="Nav-link" to={`/${faculty.name}`}>{faculty.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Hero;
