import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const UserPermissionsContext = createContext();

export const useUserPermissions = () => useContext(UserPermissionsContext);

export const UserPermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState([]);
  const [activeFaculty, setActiveFaculty] = useState("");
  const [role, setRole] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Function to fetch active faculty and permissions
  const fetchActiveFacultyAndPermissions = async () => {
    try {
      const firebaseUserId = localStorage.getItem("firebaseUserId");
      if (firebaseUserId) {
        const response = await axios.post("/api/get_user_details.php", {
          firebaseUserId,
        });
        const { activeFaculty, facultyPermissions } = response.data;

        console.log("API Response:", response.data); // Debug log
        setActiveFaculty(activeFaculty); // Update active faculty from backend response

        // Ensure permissions are an array
        const permissionsData = facultyPermissions[activeFaculty]?.permissions || [];
        const permissionsArray = Array.isArray(permissionsData)
          ? permissionsData
          : Object.values(permissionsData);

        console.log("Permissions Array:", permissionsArray); // Debug log
        setPermissions(permissionsArray); // Update permissions from backend response

        sessionStorage.setItem("selectedFaculty", activeFaculty); // Set in session storage
        localStorage.setItem("selectedFaculty", activeFaculty); // Set in local storage
        // Update the role for the current faculty
        setRole(facultyPermissions[activeFaculty]?.role || ""); // Update role from backend response
        setIsAuthenticated(true); // Set authenticated state to true
      } else {
        setIsAuthenticated(false); // Set authenticated state to false if no user ID
      }
    } catch (error) {
      console.error("Error fetching active faculty and permissions:", error);
      setIsAuthenticated(false); // Set authenticated state to false in case of error
    }
  };

  // Function to update active faculty
  const updateActiveFaculty = async (newFaculty) => {
    try {
      const firebaseUserId = localStorage.getItem("firebaseUserId");
      await axios.post("/api/set_active_faculty.php", {
        firebaseUserId,
        activeFaculty: newFaculty,
      });
      // After successfully updating the active faculty in the backend,
      // refetch the active faculty and permissions to ensure frontend is in sync with backend changes
      fetchActiveFacultyAndPermissions();
    } catch (error) {
      console.error("Error updating active faculty:", error);
    }
  };

  useEffect(() => {
    fetchActiveFacultyAndPermissions();
    // Removed call to updateActiveFaculty here as it doesn't make sense to update it on mount
  }, []); // Runs only once on component mount to get the initial set of permissions

  const hasPermission = (permission) => permissions.includes(permission);

  const value = {
    permissions,
    hasPermission,
    activeFaculty,
    updateActiveFaculty,
    isAuthenticated,
  };

  return (
    <UserPermissionsContext.Provider value={value}>
      {children}
    </UserPermissionsContext.Provider>
  );
};
