import { MDBBtn } from "mdb-react-ui-kit";
import React from "react";
import './RefreshButton.css'

function RefreshButton() {
  const handleRefreshClick = () => {
    window.location.reload();
  };

  return (
    <MDBBtn
      onClick={handleRefreshClick}
      className="start_button rounded rounded-pill bg-gradient" color="primary"
    >
      Gå til start
    </MDBBtn>
  );
}

export default RefreshButton;
