import { useState, useEffect } from "react";
import axios from "axios";
import "./Navbar.css";
import SideNav from "./SideNav";

function Navbar() {
  const [theme, setTheme] = useState("light-mode");

  useEffect(() => {
    // Retrieve theme preference from localStorage
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
      const container = document.body; // or your top-level container
      container.classList.add(savedTheme);
    }
  }, []);

  return (
    <nav className="admin_navbar_wrapper">
      <SideNav />
    </nav>
  );
}

export default Navbar;
