import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import "./UserEditModal.css";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBContainer,
  MDBInput,
  MDBSelect,
  MDBAccordion,
  MDBAccordionItem,
  MDBIcon,
  MDBCheckbox,
  MDBAlert,
  MDBTypography,
} from "mdb-react-ui-kit";

const UserEditModal = ({ user, onClose, isOpen, fetchUsers }) => {
  const [userData, setUserData] = useState({
    id: user.id,
    email: user.email || "",
    role: user.role || "",
    facultyPermissions: {},
    newPassword: "",
    firebase_user_id: user.firebase_user_id,
  });

  const [availableRoles, setAvailableRoles] = useState([]);
  const [availableFaculties, setAvailableFaculties] = useState([]);
  const [availablePermissions, setAvailablePermissions] = useState([]);
  const [error, setError] = useState(null);
  const [selectedRolesByFaculty, setSelectedRolesByFaculty] = useState({});
  const [selectedPermissionsByFaculty, setSelectedPermissionsByFaculty] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [rolesResponse, facultiesResponse, userDetailsResponse] =
          await Promise.all([
            axios.get("/api/get_roles.php"),
            axios.get("/api/get_faculties.php"),
            axios.get(`/api/get_user_details.php?userId=${user.id}`),
          ]);

        const permissions = rolesResponse.data.reduce((acc, role) => {
          acc = acc.concat(role.permissions);
          return acc;
        }, []);

        setAvailableRoles(rolesResponse.data || []);
        setAvailableFaculties(facultiesResponse.data || []);
        setAvailablePermissions([...new Set(permissions)]); // Ensure unique permissions

        const fetchedFacultyPermissions =
          userDetailsResponse.data.facultyPermissions || {};

        // Convert fetched permissions to array if they are not already
        const standardizedFacultyPermissions = {};
        for (const faculty in fetchedFacultyPermissions) {
          const { permissions, role } = fetchedFacultyPermissions[faculty];
          standardizedFacultyPermissions[faculty] = {
            permissions: Array.isArray(permissions) ? permissions : Object.values(permissions),
            role,
          };
        }

        setUserData((prevUserData) => ({
          ...prevUserData,
          email: userDetailsResponse.data.Email || "",
          facultyPermissions: standardizedFacultyPermissions,
          firebase_user_id: userDetailsResponse.data.firebase_user_id || "",
        }));
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [user.id]); // Assuming `user.id` is stable and correctly triggers the effect

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleFacultyChange = (faculty, isChecked) => {
    if (!isChecked) {
      // Remove faculty and its permissions and role when unchecked
      const updatedPermissions = { ...userData.facultyPermissions };
      delete updatedPermissions[faculty];
      setUserData((prev) => ({
        ...prev,
        facultyPermissions: updatedPermissions,
      }));
    } else {
      // Initialize permissions and role for newly checked faculty
      setUserData((prev) => ({
        ...prev,
        facultyPermissions: {
          ...prev.facultyPermissions,
          [faculty]: { permissions: [], role: "" },
        },
      }));
    }
  };

  const handlePermissionChange = (faculty, permissions) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      facultyPermissions: {
        ...prevUserData.facultyPermissions,
        [faculty]: {
          ...prevUserData.facultyPermissions[faculty],
          permissions: permissions.map((item) => item.value), // Assuming permissions is an array of objects with value
        },
      },
    }));
  };

  const handleRoleChange = (faculty, role) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      facultyPermissions: {
        ...prevUserData.facultyPermissions,
        [faculty]: {
          ...prevUserData.facultyPermissions[faculty],
          role: role.value, // Assuming role is an object with value
        },
      },
    }));
  };

  const handleSaveChanges = async () => {
    try {
      // Reformat facultyPermissions to match the expected structure
      const formattedFacultyPermissions = {};
      Object.entries(userData.facultyPermissions).forEach(
        ([faculty, { permissions, role }]) => {
          // Assuming permissions is already an array of permission values (if not, adjust accordingly)
          formattedFacultyPermissions[faculty] = { permissions, role };
        }
      );

      const userDataToUpdate = {
        ...userData,
        id: userData.id, // Ensure you're sending the correct ID
        username: user.username, // Add username to the payload
        email: userData.email,
        facultyPermissions: formattedFacultyPermissions, // Use the formatted structure
        // firebase_user_id is not needed here since the backend will handle it
      };

      // Omit newPassword if it's empty
      if (!userDataToUpdate.newPassword) {
        delete userDataToUpdate.newPassword;
      }

      // Send the updated user data to the backend
      const response = await axios.post("/api/update_user.php", userDataToUpdate);

      if (response.data && response.status === 200) {
        onClose(); // Assuming onClose is a function to close the modal or reset the state
        fetchUsers(); // Refresh the user list after successful update
      } else {
        // Handle any errors returned by the server
        throw new Error("Failed to update user. Please try again later.");
      }
    } catch (error) {
      console.error("Error updating user:", error.message || error);
      setError(
        "An error occurred while updating the user. Please try again later."
      );
    }
  };

  const roleOptions = useMemo(
    () => availableRoles.map((role) => ({ text: role.name, value: role.name })),
    [availableRoles]
  );

  const permissionOptions = useMemo(
    () =>
      availablePermissions.map((permission) => ({
        text: permission,
        value: permission,
      })),
    [availablePermissions]
  );

  return (
    <div style={{ zIndex: "1" }} className="position-relative">
      <MDBModal
        tabIndex="-1"
        open={isOpen}
        className="fade-in"
        setOpen={() => { }}
        closeOnEsc={true}
        onClose={onClose}
        staticBackdrop
        backdrop={false}
      >
        <MDBModalDialog size="xl" centered scrollable>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Endre bruker: {user.username}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={onClose}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {error && <MDBAlert color="danger">{error}</MDBAlert>}
              <MDBAccordion initialActive={1}>
                <MDBAccordionItem
                  collapseId={1}
                  headerTitle={
                    <>
                      <MDBIcon fas icon="user-circle" /> &nbsp; Brukerdata
                    </>
                  }
                >
                  <MDBInput
                    label="E-post"
                    className="my-2"
                    type="email"
                    id="email"
                    placeholder="E-post"
                    name="email"
                    value={userData.email}
                    onChange={handleInputChange}
                  />
                  <MDBInput
                    label="Passord"
                    className="my-2"
                    type="password"
                    placeholder="Reset passord"
                    value={userData.newPassword}
                    autoComplete="new-password"
                    onChange={(e) =>
                      setUserData({ ...userData, newPassword: e.target.value })
                    }
                  />
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={2}
                  headerTitle={
                    <>
                      <MDBIcon fas icon="globe-europe" /> &nbsp; Velg fakulteter
                      og roller
                    </>
                  }
                >
                  {availableFaculties.map((faculty, index) => (
                    <MDBCheckbox
                      wrapperClass="my-2"
                      labelClass="text-primary"
                      type="checkbox"
                      label={faculty.name}
                      id={`faculty-${index}`}
                      checked={
                        userData.facultyPermissions &&
                        userData.facultyPermissions.hasOwnProperty(faculty.name)
                      }
                      onChange={(e) =>
                        handleFacultyChange(faculty.name, e.target.checked)
                      }
                    />
                  ))}
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={3}
                  headerTitle={
                    <>
                      <MDBIcon fas icon="fingerprint" /> &nbsp; Velg tillatelser
                      for valgt fakulteter
                    </>
                  }
                >
                  <div className="d-flex flex-wrap w-100">
                    {Object.entries(userData.facultyPermissions).map(
                      ([faculty, details]) => (
                        <div key={faculty} className="w-25 mx-3 my-2">
                          <MDBTypography tag="h5" className="my-3 text-primary">
                            {faculty}
                          </MDBTypography>
                          <hr className="hr hr-blurry" />
                          <MDBSelect
                            search
                            multiple
                            className="my-2"
                            label="Permissions"
                            data={permissionOptions.map((option) => ({
                              ...option,
                              checked: Array.isArray(details.permissions) && details.permissions.includes(option.value), // Ensure you map through `details.permissions` array
                            }))}
                            value={details.permissions} // Directly set the selected permissions
                            onValueChange={(selectedValues) =>
                              handlePermissionChange(faculty, selectedValues)
                            }
                          />
                          <MDBSelect
                            search
                            className="my-2"
                            label="Role"
                            data={roleOptions}
                            value={details.role} // Directly set the selected role
                            onValueChange={(selectedValue) =>
                              handleRoleChange(faculty, selectedValue)
                            }
                          />
                        </div>
                      )
                    )}
                  </div>
                </MDBAccordionItem>
              </MDBAccordion>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={onClose}>
                Lukk
              </MDBBtn>
              <MDBBtn onClick={handleSaveChanges}>Lagre endringer</MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};

export default UserEditModal;
