import React, { useState, useEffect, useRef } from "react";
import "./Docs.css";
import axios from "axios";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

// Import modals
import Modal from "../../components/modal/Modal";

// Icons
import { FaSearch } from "react-icons/fa";

// Import refresh button
import RefreshButton from "../../components/refreshButton/RefreshButton";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBTypography } from "mdb-react-ui-kit";

const Docs = (props) => {
  const [clickCounts, setClickCounts] = useState({});

  const [facultyNames, setFacultyNames] = useState([]);
  const selectedFaculty = localStorage.getItem("selectedFaculty");

  // Count button clicks

  const handleModalClick = (modalId) => {
    // Increment the click count for the clicked modal
    const newClickCounts = { ...clickCounts };
    newClickCounts[modalId] = (newClickCounts[modalId] || 0) + 1;
    setClickCounts(newClickCounts);

    // Send the updated click count to the server
    axios.post(`/api/updateClickCount.php`, {
      modalId,
      clickCount: newClickCounts[modalId],
    });
  };

  const [modals, setModals] = useState([]);
  const facultyName = window.location.pathname.split("/")[1]; // Extract facultyName from URL

  useEffect(() => {
    axios
      .get(`/api/get_modal_data.php?facultyName=${facultyName}`)
      .then((response) => {
        setModals(response.data);
      });
  }, [facultyName]);

  const [facultyBackground, setFacultyBackground] = useState(""); // State for facultyBackground

  useEffect(() => {
    // Fetch facultyBackground data
    axios
      .get(`/api/getFacultySettings.php?facultyName=${facultyName}`)
      .then((response) => {
        const background = response.data.facultyBackground
          ? `url(${response.data.facultyBackground})`
          : "#fff";
        setFacultyBackground(background);
      })
      .catch((error) => {
        console.error("Error fetching faculty settings:", error);
        setFacultyBackground("#fff"); // Set to default if there's an error
      });
  }, [facultyName]);

  // Filter modals after search
  const [filteredModals, setFilteredModals] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Assuming `modalName` is a property of each modal object in the `modals` state
    const matchingModals = modals.filter((modal) =>
      modal.modalName.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredModals(matchingModals);
    setIsFocused(false);
  };

  // Keyboard related states and refs
  const [input, setInput] = useState("");
  const [layout, setLayout] = useState("default");
  const keyboard = useRef();
  const searchInput = useRef(null); // Ensure this ref is attached to your input element

  // New: State to track if the input is focused
  const [isFocused, setIsFocused] = useState(false);

  const onChange = (input) => {
    setInput(input);
    console.log("Input changed", input);
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = (button) => {
    console.log("Button pressed", button);
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const onChangeInput = (event) => {
    const inputVal = event.target.value;
    setInput(inputVal); // Update the input state

    // Perform search filtering in real-time
    const matchingModals = modals.filter((modal) =>
      modal.modalName.toLowerCase().includes(inputVal.toLowerCase())
    );
    setFilteredModals(matchingModals);
  };

  // Prevent default mouse down behavior to avoid input blur
  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  // Focus management for input and keyboard interaction
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  // Use onClick for Keyboard wrapper if needed or adjust according to your logic
  const onClick = () => {
    // Manually set focus back to the input if necessary
    searchInput.current.focus();
  };


  // Fetch faculty data and refresh timer
  useEffect(() => {
    axios
      .get("/api/get_faculties.php")
      .then((response) => {
        setFacultyNames(response.data.map((faculty) => faculty.name));
      })
      .catch((error) => {
        console.error("Error fetching faculty names:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`/api/getFacultySettings.php?facultyName=${selectedFaculty}`)
      .then((response) => {
        if (response.data && response.data.refreshTimer) {
          setRefreshTimer(parseInt(response.data.refreshTimer, 10));
          console.log("Refresh timer after parse:", response.data.refreshTimer);
        }
      })
      .catch((error) => {
        console.error("Error fetching refresh timer:", error);
      });
  }, [selectedFaculty]);


  // Inactivity timer

  const [refreshTimer, setRefreshTimer] = useState(300000); // Default to 5 minutes
  const inactivityTimerRef = useRef(null);

  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimerRef.current);
    inactivityTimerRef.current = setTimeout(() => {
      console.log('Inactivity timer expired, refreshing page...');
      window.location.reload();
    }, refreshTimer);
  };

  useEffect(() => {
    const activityDetected = () => {
      resetInactivityTimer();
    };

    resetInactivityTimer(); // Reset timer on mount and when refreshTimer changes
    window.addEventListener("mousemove", activityDetected);
    window.addEventListener("keydown", activityDetected);
    window.addEventListener("touchstart", activityDetected);

    return () => {
      window.removeEventListener("mousemove", activityDetected);
      window.removeEventListener("keydown", activityDetected);
      window.removeEventListener("touchstart", activityDetected);
      clearTimeout(inactivityTimerRef.current);
    };
  }, [refreshTimer]);






  return (
    <MDBContainer
      fluid
      id="docs"
      style={{
        backgroundImage: facultyBackground.includes("url")
          ? `linear-gradient(45deg, rgba(194, 194, 194, 0.8) 100%, transparent), ${facultyBackground}`
          : facultyBackground,
      }}
    >
      <RefreshButton />
      <MDBContainer fluid className="viken__docs-container_search">
        <MDBTypography tag='h5' className="text-dark mt-3">
          Hva kan vi hjelpe deg med?
        </MDBTypography>
        <form onSubmit={handleSubmit} className="search-box">
          <MDBInput
            ref={searchInput}
            type="text"
            value={input}
            onChange={onChangeInput}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder="Søk enkeltord: Word, Epost, Print, Passord, Office, osv"
            name="search"
            className="search-text mb-2"
            autoComplete="off"
            id="search"
          />
          <button type="submit" className="search-btn bg-primary">
            <FaSearch className="viken__docs-search_icon" />
          </button>
        </form>

        <div
          onMouseDown={handleMouseDown}
          onClick={onClick}
          style={{ opacity: isFocused ? 1 : 0 }}
          className={`d-flex justify-content-center align-items-center ${
            isFocused ? "fade-in-up" : "fade-out"
          }`}
        >
          <Keyboard
            keyboardRef={(r) => (keyboard.current = r)}
            layoutName={layout}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
        </div>
      </MDBContainer>
      <MDBContainer
        fluid
        className="d-flex flex-wrap row justify-content-center w-100"
      >
        <MDBContainer className="d-flex justify-content-center">
          <MDBRow className="flex-wrap justify-content-center">
            {(filteredModals.length > 0 ? filteredModals : modals).map(
              (modal) => (
                <MDBCol lg="4" className="d-inline-flex">
                  <Modal
                    key={modal.id}
                    modalData={modal}
                    handleModalClick={handleModalClick}
                  />
                </MDBCol>
              )
            )}
          </MDBRow>
        </MDBContainer>
      </MDBContainer>
    </MDBContainer>
  );
};

export default Docs;
