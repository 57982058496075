import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import './FacultyRegistration.css'
import Navbar from "../../admin/roaldvgs/navbar/Navbar";
import { MDBBtn, MDBContainer, MDBInput } from "mdb-react-ui-kit";
import { useUserPermissions } from "../../context/UserPermissionsContext";

const FacultyRegistration = () => {
  const [formData, setFormData] = useState({
    facultyName: "",
    emails: "",
    Skolenavn: "",
    // Add other fields as needed
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "/api/registerFaculty.php",
        new URLSearchParams(formData), // Serialize the data as x-www-form-urlencoded
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded", // Set the content type
          },
        }
      );
      console.log("Faculty registered:", response.data);

      // Check the response for success or error
      if (response.status === 201) {
        // Registration was successful, display a success message
        // You can also reset the form or redirect the user
        window.alert("Registrering velykket!"); // You can use a modal or a more user-friendly notification
      } else {
        // Registration failed, display an error message
        window.alert("Registrering feilet! noob"); // You can use a modal or a more user-friendly notification
      }
    } catch (error) {
      console.error("Error registering faculty:", error);
      // Handle any network or other errors here, you can display an error message to the user
      alert(
        "En feil oppsto under registreringen. hvis du mener dette er en feil i systemet, ta kontakt med mathiasd@viken.no"
      );
    }
  };

  const [theme, setTheme] = useState("light-mode");

  useEffect(() => {
    // Retrieve theme preference from localStorage
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
      const container = document.body; // or your top-level container
      container.classList.add(savedTheme);
    }
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFaculty, setSelectedFaculty] = useState(null);
  const [faculties, setFaculties] = useState([]);

  useEffect(() => {
    // Fetch faculties from your API and update the 'faculties' state
    axios
      .get("/api/get_faculties.php")
      .then((response) => {
        setFaculties(response.data);
      })
      .catch((error) => {
        console.error("Error fetching faculties:", error);
      });
  }, []);

  const handleFacultySelection = (faculty) => {
    setSelectedFaculty(faculty);
    setIsModalOpen(false); // Close the modal after selection
  };

  const handleDeleteFaculty = () => {
    if (selectedFaculty) {
      // Make an API request to delete the selected faculty
      axios
        .delete(`/api/delete_faculty.php?id=${selectedFaculty.id}`)
        .then((response) => {
          // Handle successful deletion
          console.log(response.data.message);
          // Remove the deleted faculty from the 'faculties' state
          setFaculties(
            faculties.filter((faculty) => faculty.id !== selectedFaculty.id)
          );
          setSelectedFaculty(null); // Clear the selected faculty
          window.alert("Sletting velykket!");
        })
        .catch((error) => {
          // Handle error
          console.error("Error deleting faculty:", error);
          window.alert("Sletting feilet! Error:", error);
        });
    }
  };

  // Handle permissions
  const { hasPermission } = useUserPermissions();

  return (
    <MDBContainer className="faculty_administration">
      <Navbar />
      {hasPermission("FacultyCreate") && (
      <div className="faculty_administration-wrapper section__padding">
        <h3>Registrer ny lokasjon</h3>
        <form onSubmit={handleSubmit}>
          <div className="form_input">
            <MDBInput
              label="Skolens navnforkortelse"
              type="text"
              id="facultyName"
              name="facultyName"
              value={formData.facultyName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form_input">
            <MDBInput
              label="E-post - IT"
              id="emails"
              name="emails"
              type="email"
              value={formData.emails}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form_input">
            <MDBInput
              label="Skolenavn (Eks, Ås VGS)"
              type="text"
              id="Skolenavn"
              name="Skolenavn"
              value={formData.Skolenavn}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <MDBBtn className="m-2" type="submit">
              Registrer
            </MDBBtn>
          </div>
        </form>
        <MDBContainer className="d-flex align-items-start flex-column">
        <h4 className="mt-4">Slett en lokasjon</h4>
        <div className="delete_faculty-wrapper">
          <MDBBtn color="danger" onClick={() => setIsModalOpen(true)}>
            Åpne sletting
          </MDBBtn>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            className="faculty_delete-wrapper"
          >
            <h4>Velg en lokasjon du ønsker å fjerne:</h4>
            <ul>
              {faculties.map((faculty) => (
                <li key={faculty.id}>
                  <MDBBtn
                    color="secondary"
                    onClick={() => handleFacultySelection(faculty)}
                  >
                    {faculty.name}
                  </MDBBtn>
                </li>
              ))}
            </ul>
          </Modal>
          {selectedFaculty && (
            <div>
              <p>Valgt lokasjon: {selectedFaculty.name}</p>
              <MDBBtn color="danger" onClick={handleDeleteFaculty}>
                SLETT
              </MDBBtn>
            </div>
          )}
        </div>
          </MDBContainer>
      </div>
      )}
    </MDBContainer>
  );
};

export default FacultyRegistration;
