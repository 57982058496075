import { MDBBtn, MDBContainer, MDBIcon, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../roaldvgs/navbar/Navbar';

  const selectedFaculty = localStorage.getItem("selectedFaculty");
  const handleBackButton = () => {
    window.location.href = `/Admin/${selectedFaculty}`;
  };


const NoAccess = () => {
  return (
    <>
      <Navbar />
      <MDBContainer
        className="mt-4 d-flex flex-column justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div
          className="d-flex flex-column
         px-4 py-3 align-items-center"
        >
          <div className="bg-primary bg-gradient px-3 py-2 rounded-circle square">
            <MDBIcon
              fas
              icon="thumbs-down"
              size="3x"
              className="my-2"
              color="white"
            />
          </div>
          <MDBTypography tag="h3" className="display-3 mx-2">
            Ingen tilgang
          </MDBTypography>
        </div>
        <p className="my-2 p-3 text-center">
          Det ser ut til at du ikke har tilgang ... <br />
          Ta kontakt med nærmeste leder hvis du mener dette er feil!
        </p>
        <MDBBtn color="link" onClick={handleBackButton}>
          Gå hjem
        </MDBBtn>
      </MDBContainer>
    </>
  );
}

export default NoAccess