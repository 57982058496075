import axios from "axios";
import {
  MDBAlert,
  MDBBtn,
  MDBContainer,
  MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader,
  MDBModalTitle, MDBTable, MDBTableBody, MDBTableHead,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import UserEditModal from "../../userEditModal/UserEditModal";
import Navbar from "../navbar/Navbar";
import "./UserList.css";
import NoAccess from "../../noAccess/NoAccess"; // If no permission to view component, display NoAccess component
import { useUserPermissions } from "../../../context/UserPermissionsContext";

// ICONS
import { SlUser } from "react-icons/sl";
import SuccessAlert from "../../components/successAlert/successAlert";

function UserList(props) {
  const [usernames, setUsernames] = useState([]);
  const [userIds, setUserIds] = useState([]); // Add state for userIds
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [usernameToDelete, setUsernameToDelete] = useState(null);
  const selectedFaculty = localStorage.getItem("selectedFaculty");
  const [users, setUsers] = useState([]);

  // Direct user to profile page when clicking on profile image
  const history = useHistory();

  const handleImageClick = (username) => {
    history.push(`/profile/${username}`);
  };

  const [selectedUserForEdit, setSelectedUserForEdit] = useState(null);

  const [centredModal, setCentredModal] = useState(false);

  const toggleOpen = (username) => {
    setUsernameToDelete(username); // Set the username to delete
    setCentredModal(!centredModal); // Toggle the modal open state
  };

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editModalTimeout, setEditModalTimeout] = useState(null);

  const closeModal = () => {
    setIsModalOpen(false);
    setUsernameToDelete(null); // Reset username to delete
    setCentredModal(false); // Ensure modal is closed
  };

  const openEditModal = (user) => {
    setSelectedUserForEdit(user);
    const timeout = setTimeout(() => {
      setIsEditModalOpen(true);
    }, 300); // 300ms delay
    setEditModalTimeout(timeout);
  };

  const closeEditModal = () => {
    if (editModalTimeout) {
      clearTimeout(editModalTimeout);
      setEditModalTimeout(null);
    }
    setSelectedUserForEdit(null);
    setIsEditModalOpen(false);
  };

  // Make sure to clear the timeout when the component unmounts
  useEffect(() => {
    return () => {
      if (editModalTimeout) {
        clearTimeout(editModalTimeout);
      }
    };
  }, [editModalTimeout]);

  const fetchUsers = () => {
    axios
      .get(`/api/get_users.php?faculty=${selectedFaculty}`)
      .then((response) => {
        const fetchedUserData = response.data;
        const uniqueUsernames = Array.from(
          new Set(fetchedUserData.map((user) => user.username))
        );
        const uniqueUserIds = Array.from(
          new Set(fetchedUserData.map((user) => user.id))
        );
        setUsernames(uniqueUsernames);
        setUserIds(uniqueUserIds);
        setUsers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, [selectedFaculty]);

  const deleteUser = () => {
    axios
      .post(
        "/api/delete_user.php",
        { username: usernameToDelete },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("User deletion successful:", response.data.message);
        // Refresh user list after deletion
        setUsernames(usernames.filter((name) => name !== usernameToDelete));
        closeModal();
        <SuccessAlert />;
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
      });
  };

  // Toggle light/dark mode for table.
  const [theme, setTheme] = useState();

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light";
    setTheme(savedTheme);
  }, []);

  // Permissions
  const { hasPermission } = useUserPermissions();

  // Get logged in user's username from localStorage
  const loggedInUsername = localStorage.getItem("username");

  return (
    <div className="wrapper">
      <Navbar />
      <MDBContainer className="mt-5">
        <div className="viken__userlist-header">
          <h4>Brukere registrert på {selectedFaculty}:</h4>
        </div>
        <MDBTable responsive className="bg-transparent mt-5">
          <MDBTableHead>
            <tr>
              <th scope="col">Profilbilde</th>
              <th scope="col">Brukernavn</th>
              <th scope="col">Handling</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {users.map((user) => (
              <tr key={user.id}>
                <td scope="row">
                  {user.profilepicture ? (
                    <img
                      src={user.profilepicture}
                      alt={`Profile of ${user.username}`}
                      className="user_icon"
                      onClick={() => handleImageClick(user.username)}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <img
                      src={user.profilepicture}
                      alt={`Profile of ${user.username}`}
                      className="user_icon"
                      onClick={() => handleImageClick(user.username)}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </td>
                <td scope="row">
                  <div
                    className="d-flex align-items-center"
                    style={{ height: "60px" }}
                  >
                    {user.username}
                  </div>
                </td>
                <td>
                  <div
                    className="d-flex align-items-center"
                    style={{ height: "60px" }}
                  >
                    {hasPermission("UserDelete") && (
                      <MDBBtn
                        color="danger"
                        className="mx-1"
                        size="sm"
                        onClick={() => toggleOpen(user.username)}
                      >
                        Slett bruker
                      </MDBBtn>
                    )}
                    {hasPermission("UserEdit") && user.username !== loggedInUsername && (
                      <MDBBtn
                        size="sm"
                        className="mx-1"
                        color="secondary"
                        onClick={() => openEditModal(user)}
                      >
                        Rediger bruker
                      </MDBBtn>
                    )}
                    <MDBBtn
                      size="sm"
                      className="mx-1"
                      color="primary"
                      onClick={() => handleImageClick(user.username)}
                    >
                      Besøk
                    </MDBBtn>
                  </div>
                </td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>

        <MDBModal
          tabIndex="-1"
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          open={centredModal}
          setOpen={setCentredModal}
        >
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Vil du slette bruker?</MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={toggleOpen}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <p>
                  Hvis du sletter denne kontoen, vil alt av data registrert på
                  denne brukeren fjernes fra databasen. Brukerveiledninger og
                  andre konfigurasjoner gjort fra denne brukeren vil fortsatt
                  være aktive etter eliminering.
                </p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleOpen}>
                  Avbryt
                </MDBBtn>
                <MDBBtn color="danger" onClick={deleteUser}>
                  Bekreft
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>

        {selectedUserForEdit && (
          <UserEditModal
            user={selectedUserForEdit}
            onClose={closeEditModal}
            isOpen={isEditModalOpen}
            fetchUsers={fetchUsers} // Pass fetchUsers as a prop
          />
        )}
      </MDBContainer>
    </div>
  );
}

export default UserList;
