import { MDBAlert } from 'mdb-react-ui-kit';
import React from 'react'

const SuccessAlert = () => {
  return (
    <MDBAlert
      position='bottom-right'
      defaultOpen
      autohide
      color="success"
    >
      Handlingen var velykket!
    </MDBAlert>
  );
}

export default SuccessAlert