import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Modal.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
  MDBCard,
  MDBRipple,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBContainer,
  MDBBadge,
} from "mdb-react-ui-kit";

const Modal = ({ modalData, handleModalClick }) => {
  const [showModal, setShowModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = () => setBasicModal(!basicModal);

  const handleRating = (modalId, rating) => {
    if (isButtonDisabled) {
      return;
    }

    const payload = {
      modalId: modalId,
      rating: rating,
    };

    axios
      .post("/api/update_rating.php", payload)
      .then((response) => {
        console.log(response.data);
        setIsButtonDisabled(true);
        alert("Takk for din mening!");

        // Set timeout for 3 seconds to reload the page
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    // Only set up the 10-second timeout if showFeedback is true
    if (showFeedback) {
      const timeout = setTimeout(() => {
        if (!isButtonDisabled) {
          window.location.reload();
        }
      }, 10000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showFeedback, isButtonDisabled]);

  const handleClick = () => {
    setShowModal(true);
    toggleOpen();
    handleModalClick(modalData.id); // Call the function from Docs.jsx to handle the click count
    console.log("handleClick clicked")
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
    arrows: true,
  };

  const getGuideImagesUrl = (relativePath) => {
    // Construct the complete image URL with the correct base URL
    const baseUrl = "https://portal.dencker.no/api/Guide_image_uploads/"; // Modify this to your server's URL
    return relativePath;
  };

  return (
    <MDBContainer fluid className="w-100 h-auto d-flex rounded my-4">
      <MDBCard
        onClick={() => {
          handleClick();
        }}
        className="w-100 m-2 h-100 rounded d-inline-flex"
        style={{ cursor: "pointer" }}
      >
        <MDBRipple
          rippleColor="light"
          rippleTag="div"
          className="bg-image hover-overlay"
        >
          <MDBCardImage src={modalData.modalImages} fluid alt="" />
          <a>
            <div
              className="mask"
              style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
            ></div>
          </a>
        </MDBRipple>
        <MDBCardBody id="modal-card-body-wrapper">
          <MDBCardTitle style={{ height: "55px" }}>
            {modalData.modalName} <br />
            {modalData.modalMac === 1 && <span><MDBBadge pill className="mx-1" color="danger">MAC</MDBBadge></span>}
            {modalData.modalWindows === 1 && <span><MDBBadge pill className="mx-1" color="success">WINDOWS</MDBBadge> </span>}
            {modalData.modalAndroid === 1 && <span><MDBBadge pill className="mx-1" color="primary">ANDROID</MDBBadge> </span>}
            {modalData.modalIphone === 1 && <span><MDBBadge pill className="mx-1" color="warning">IPHONE</MDBBadge> </span>}
            {modalData.modalWeb === 1 && <span><MDBBadge pill className="mx-1" color="info">WEB</MDBBadge></span>}
          </MDBCardTitle>
          <MDBCardText id="modal-card-body" className="flex-column">
            <div className="tags">
              <p className="lead">Tags:</p>
              {modalData.modalTags &&
                modalData.modalTags.split(",").map((tag) => (
                  <p key={tag} className="tagsText ms-2 p-0">
                    {tag}
                  </p>
                ))}
            </div>
            <p style={{ height: "100px" }} id="modal-description">
              {modalData.modalDescription
              }</p>
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>

      {showModal && (
        <div className="modal-wrapper-full" id="Modal-1">
          <MDBModal open={basicModal} setopen={setBasicModal} tabIndex="-1">
            <MDBModalDialog size="fullscreen">
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle className="text-dark">
                    {modalData.modalName},{" "}
                    {modalData.modalMac === 1 && <span>(Mac)</span>}
                    {modalData.modalWindows === 1 && <span>(Windows)</span>}
                    {modalData.modalAndroid === 1 && <span>(Android)</span>}
                    {modalData.modalIphone === 1 && <span>(iPhone)</span>}
                    {modalData.modalWeb === 1 && <span>(Web)</span>}
                  </MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={() => {
                      setShowFeedback(true);
                      setShowModal(false);
                      toggleOpen();
                    }}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody className="p-5">
                  <Slider {...settings}>
                    {modalData.modalContentImages &&
                      modalData.modalContentImages
                        .split(",") // Split the comma-separated string
                        .map((imageUrl, index) => {
                          const trimmedImageUrl = imageUrl.trim();
                          const completeImageUrl =
                            getGuideImagesUrl(trimmedImageUrl);
                          return (
                            <div className="img-wrapper p-5" key={index}>
                              <img
                                src={completeImageUrl}
                                className="img-fluid m-auto"
                                alt={`Image ${index + 1}`}
                              />
                            </div>
                          );
                        })}
                  </Slider>
                </MDBModalBody>

                <MDBModalFooter></MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </div>
      )}
      {showFeedback && (
        <div className="viken__modal-feedback-container">
          <p>Var denne informasjonen nyttig?</p>
          <div className="emoji_wrapper">
            <MDBBtn
              color="tertiary"
              rounded
              alt="Good"
              className="feedback-smile m-2"
              onClick={() => handleRating(modalData.id, "Good")}
            >
              <MDBIcon far size="6x" color="success" icon="grin-beam" />
            </MDBBtn>
            <MDBBtn
              color="tertiary"
              rounded
              alt="Bad"
              className="feedback-sad m-2"
              onClick={() => handleRating(modalData.id, "Bad")}
            >
              <MDBIcon far size="6x" color="danger" icon="frown-open" />
            </MDBBtn>
          </div>
          <MDBBtn color="secondary" onClick={reloadPage}>
            Avslutt
          </MDBBtn>
        </div>
      )}
    </MDBContainer>
  );
};

export default Modal;
