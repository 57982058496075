import React, { useState, useEffect } from "react";
import axios from "axios";
import ModalList from "../modalList/ModalList";

const EditModalForm = () => {
  const [selectedModal, setSelectedModal] = useState(null);
  const [editFormData, setEditFormData] = useState({
    modalName: "",
    modalDescription: "",
    modalTags: "",
    modalImage: null, // Change to null to store the selected file
  });

  // Load existing modal data when selectedModal changes
  useEffect(() => {
    if (selectedModal) {
      // Fetch the modal data by selectedModal ID and populate the form
      axios
        .get(`/api/get_modal_data.php?id=${selectedModal}`)
        .then((response) => {
          const modalData = response.data;
          setEditFormData({
            modalName: modalData.modalName,
            modalDescription: modalData.modalDescription,
            modalTags: modalData.modalTags,
            modalImage: null, // You can set the image here if needed
          });
        })
        .catch((error) => {
          console.error("Error fetching modal data:", error);
        });
    }
  }, [selectedModal]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("modalName", selectedModal); // old modal name
    formData.append("newModalName", editFormData.modalName); // new modal name
    formData.append("modalDescription", editFormData.modalDescription);
    formData.append("modalTags", editFormData.modalTags);
    formData.append("modalImage", editFormData.modalImage);

    // Check if an image is selected before appending it to form data
    if (editFormData.modalImage !== null) {
      formData.append("modalImage", editFormData.modalImage);
    }

    // Send form data to backend PHP script for updating
    fetch("api/EditModal.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // Handle success or display messages to the user
      })
      .catch((error) => {
        console.error(error);
        // Handle error or display messages to the user
      });
  };

  const handleFileInputChange = (e) => {
    // Handle file input change here
    setEditFormData({
      ...editFormData,
      modalImage: e.target.files[0], // store the selected file in the state
    });
  };

  const handleContentFileInputChange = (event) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const imagesArray = Array.from(files);

      setEditFormData({
        ...editFormData,
        modalContentImages: imagesArray,
      });
    }
  };


        const [theme, setTheme] = useState("light-mode");

        useEffect(() => {
          // Retrieve theme preference from localStorage
          const savedTheme = localStorage.getItem("theme");
          if (savedTheme) {
            setTheme(savedTheme);
            const container = document.body; // or your top-level container
            container.classList.add(savedTheme);
          }
        }, []);
    

  // Define your state and functions to handle form changes, image uploads, etc.
  return (
    <div className="EditModalWrapper">
      <div className="modal-list">
        <ModalList onSelectModal={setSelectedModal} />
      </div>
      {selectedModal && (
        <form onSubmit={handleFormSubmit}>
          <div className="form_input-create">
            <label>Nytt navn på brukerveiledning:</label>
            <input
              type="text"
              value={editFormData.modalName}
              onChange={(e) =>
                setEditFormData({
                  ...editFormData,
                  modalName: e.target.value,
                })
              }
            />
            <label>Beskrivelse av brukerveiledning:</label>
            <textarea
              type="text"
              value={editFormData.modalDescription}
              onChange={(e) =>
                setEditFormData({
                  ...editFormData,
                  modalDescription: e.target.value,
                })
              }
            />

            <label>Tags (separert med ","):</label>
            <input
              type="text"
              value={editFormData.modalTags}
              onChange={(e) =>
                setEditFormData({
                  ...editFormData,
                  modalTags: e.target.value,
                })
              }
            />
            <hr className="divider-md-light" style={{ margin: "0.5rem 0" }} />
          </div>
          <div className="form_input-create">
            <label>Forside bilde (300x300px):</label>
            <input
              type="file"
              name="modalImage"
              onChange={handleFileInputChange}
            />
            <p className="MaxFileSizeText">
              Maksimal filstørrelse: 500 KB
              <br />
              Opplastet filstørrelse:{" "}
              {editFormData.modalImage
                ? (editFormData.modalImage.size / 1024).toFixed(2) + " KB"
                : "No file uploaded"}
            </p>
          </div>

          <label>Brukerveiledning bilder (500x500px):</label>
          <input
            type="file"
            multiple // Allow multiple file selection
            onChange={handleContentFileInputChange}
          />
          <div className="form_input-create">
            <button type="submit" className="btn">
              Produser guide
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditModalForm;
