import React from 'react';
import './Links.css';

import { Link } from "react-router-dom";
import { MDBContainer } from 'mdb-react-ui-kit';

import Logo from '../../../../assets/Dencker-banner-light-transparent.png';

const Links = () => {
  const handleLinkClick = () => {
    const currentUrl = window.location.href;
    localStorage.setItem('facultyURL', currentUrl);
  };

  return (
    <MDBContainer fluid className='mt-5 position-relative align-items-center bg-dark d-flex' style={{ bottom: "0%" }}>
      <MDBContainer className="d-flex align-items-center">
        <div className="link" style={{ flex: "2" }}>
          <Link
            id='link-hover'
            style={{ margin: "0.25rem 0rem", color: "#c3c3c3", fontFamily: "var(--font-family)" }}
            to="/login"
            onClick={handleLinkClick}
          >
            For ansatte
          </Link>
        </div>
        <img src={Logo} style={{ height: "60px" }} alt="Logo" />
      </MDBContainer>
    </MDBContainer>
  );
};

export default Links;
