import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AdminModal.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
  MDBCard,
  MDBRipple,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
} from "mdb-react-ui-kit";

const AdminModal = ({ modalData, handleModalClick }) => {
  const [showModal, setShowModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = () => setBasicModal(!basicModal);

  const handleClick = () => {
    setShowModal(true);
    toggleOpen();
  };


  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
    arrows: true,
  };

  const getGuideImagesUrl = (relativePath) => {
    // Construct the complete image URL with the correct base URL
    const baseUrl = "https://portal.dencker.no/api/Guide_image_uploads/"; // Modify this to your server's URL
    return relativePath;
  };

  return (
    <div id="modal-wrapper" className="w-100 mx-auto my-4 h-auto d-inline-flex rounded">
      <MDBCard
        onClick={() => {
          handleClick();
        }}
        className="w-100 m-2 h-100 rounded d-flex"
        style={{ cursor: "pointer" }}
      >
        <MDBRipple
          rippleColor="light"
          rippleTag="div"
          className="bg-image hover-overlay"
        >
          <MDBCardImage src={modalData.modalImages} fluid alt="" />
          <a>
            <div
              className="mask"
              style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
            ></div>
          </a>
        </MDBRipple>
        <MDBCardBody id="modal-card-body-wrapper">
          <MDBCardTitle>
            {modalData.modalMac === 1 && <span>[Mac] </span>}
            {modalData.modalWindows === 1 && <span>[Windows] </span>}
            {modalData.modalName}
          </MDBCardTitle>
          <MDBCardText id="modal-card-body" className="flex-column">
            <div className="tags">
              <p className="lead">Tags:</p>
              {modalData.modalTags &&
                modalData.modalTags.split(",").map((tag) => (
                  <p key={tag} className="tagsText ms-2">
                    {tag}
                  </p>
                ))}
            </div>
            <hr className="hr hr-blurry" />
            <p style={{ height: "100px" }} id="modal-description">
              {modalData.modalDescription}
            </p>
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>

      {showModal && (
        <div className="modal-wrapper-full" id="Modal-1">
          <MDBModal open={basicModal} setopen={setBasicModal} tabIndex="-1">
            <MDBModalDialog size="fullscreen">
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>
                    {modalData.modalName},{" "}
                    {modalData.modalMac === 1 && <span>(Mac)</span>}
                    {modalData.modalWindows === 1 && <span>(Windows)</span>}
                  </MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={() => {
                      setShowFeedback(true);
                      setShowModal(false);
                      toggleOpen();
                    }}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody className="p-5">
                  <Slider {...settings}>
                    {modalData.modalContentImages &&
                      modalData.modalContentImages
                        .split(",") // Split the comma-separated string
                        .map((imageUrl, index) => {
                          const trimmedImageUrl = imageUrl.trim();
                          const completeImageUrl =
                            getGuideImagesUrl(trimmedImageUrl);
                          return (
                            <div className="img-wrapper p-5" key={index}>
                              <img
                                src={completeImageUrl}
                                className="img-fluid m-auto"
                                alt={`Image ${index + 1}`}
                              />
                            </div>
                          );
                        })}
                  </Slider>
                </MDBModalBody>

                <MDBModalFooter></MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </div>
      )}
    </div>
  );
};

export default AdminModal;
