import React, { useState, useEffect } from "react";
import axios from "axios";

// Icons
import { FaUpload } from "react-icons/fa";
import {
  MDBBtn,
  MDBContainer,
  MDBInput,
  MDBTextArea,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBCheckbox,
  MDBIcon,
  MDBChipsInput
} from "mdb-react-ui-kit";


import { useUserPermissions } from "../../context/UserPermissionsContext";

const CreateModalForm = () => {

    const [toggleOneModal, setToggleOneModal] = useState(false);
    const [toggleTwoModal, setToggleTwoModal] = useState(false);
    const [toggleThreeModal, setToggleThreeModal] = useState(false);
    const [toggle4Modal, setToggle4Modal] = useState(false);
    const [toggle5Modal, setToggle5Modal] = useState(false);
    const [toggle6Modal, setToggle6Modal] = useState(false);
    const [toggle7Modal, setToggle7Modal] = useState(false);


  const [createFormData, setCreateFormData] = useState({
    isModalMac: false,
    isModalWindows: false,
    isModalAndroid: false,
    isModalIphone: false,
    isModalWeb: false,
    modalName: "",
    modalDescription: "",
    modalTags: [],
    modalImage: null,
    modalContentImages: [], // Initialize an array to store multiple images
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const isConfirmed = window.confirm(
      "Er du sikker på at du ønsker å lage denne brukerveiledningen? Den vil bli aktiv med en gang."
    );

    if (isConfirmed) {
      const username = localStorage.getItem("username");

      const formData = new FormData();
      formData.append("facultyName", localStorage.getItem("selectedFaculty"));
      formData.append("isModalMac", createFormData.isModalMac ? 1 : 0);
      formData.append("isModalWindows", createFormData.isModalWindows ? 1 : 0);
      formData.append("isModalAndroid", createFormData.isModalAndroid ? 1 : 0);
      formData.append("isModalIphone", createFormData.isModalIphone ? 1 : 0);
      formData.append("isModalWeb", createFormData.isModalWeb ? 1 : 0);
      formData.append("modalName", createFormData.modalName);
      formData.append("modalDescription", createFormData.modalDescription);
      formData.append("modalTags", createFormData.modalTags.join(", ")); // Join array elements into a string
      formData.append("modalImage", createFormData.modalImage);
      formData.append("createdBy", username);

      createFormData.modalContentImages.forEach((image, index) => {
        formData.append(`modalContentImages[${index}]`, image);
      });

      try {
        const response = await axios.post("/api/create_modal.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(response.data);
      } catch (error) {
        console.error("Error adding modal:", error);
      }
    } else {
      console.log("Form submission canceled.");
    }
  };

  const [theme, setTheme] = useState("light-mode");

  useEffect(() => {
    // Retrieve theme preference from localStorage
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
      const container = document.body; // or your top-level container
      container.classList.add(savedTheme);
    }
  }, []);

  const handleCheckboxChange = (checkboxName, value) => {
    setCreateFormData((prevFormData) => ({
      ...prevFormData,
      isModalMac: false,
      isModalWindows: false,
      isModalAndroid: false,
      isModalIphone: false,
      isModalWeb: false,
      [checkboxName]: value
    }));
  };


  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > 500000) {
      alert("Error: File size exceeds the maximum limit of 500 KB.");
    } else {
      setCreateFormData({
        ...createFormData,
        modalImage: file,
      });
    }
  };

  const handleContentFileInputChange = (event) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const imagesArray = Array.from(files);

      setCreateFormData({
        ...createFormData,
        modalContentImages: imagesArray,
      });
    }
  };

  const handleDownload = () => {
    const pptxFileUrl = "https://portal.dencker.no/PDF/mal.potx";

    const a = document.createElement("a");
    a.href = pptxFileUrl;
    a.download = "mal.potx";
    a.style.display = "none";

    a.addEventListener("error", () => {
      console.error(
        "En feil har oppstått under nedlastningen. Prøv på nytt igjen om noen minutter."
      );
    });

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

// handle permissions
const { hasPermission } = useUserPermissions();

  return (
    <MDBContainer>
      <h2 className="modalpanel_header">Lag en ny brukerveiledning</h2>
      <label>Bruk denne malen når du lager brukerveiledninger</label> <br />
      <MDBBtn className="m-2" color="secondary" onClick={handleDownload}>
        Last ned mal
      </MDBBtn>
      <MDBBtn
        className="m-2"
        onClick={() => setToggleOneModal(!toggleOneModal)}
      >
        Start produksjon
      </MDBBtn>
      <MDBContainer className="mt-5">
        <h4>Viktig info før du starter:</h4>
        <ul className="mt-5">
          <li color="link">
            Brukerveiledning bilder og forside skal være 1920x1080 (eksportert
            som JPG fra powerpoint)
          </li>
          <li color="link">
            Hver brukerveiledning skal å være formelt og pent utformet i henhold
            til mal
          </li>
          <li color="link">
            Brukerveiledningen blir aktiv med en gang du publiserer.
          </li>
        </ul>
        <div className="ratio ratio-16x9">
          <video src="/PDF/2023-12-07%2011-35-57.mp4" controls preLoad="auto">
            <source src="/PDF/2023-12-07%2011-35-57.mp4" type="video/mp4" />
          </video>
        </div>
      </MDBContainer>
      <form
        onSubmit={handleFormSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        enctype="multipart/form-data"
        preventDefault={true}
      >
        <MDBModal
          open={toggleOneModal}
          setOpen={setToggleOneModal}
          tabIndex="-1"
        >
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Velg device</MDBModalTitle>
                <MDBBtn
                  type="button"
                  className="btn-close"
                  color="none"
                  onClick={() => setToggleOneModal(!toggleOneModal)}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <MDBContainer className="d-flex flex-wrap">
                  <MDBCheckbox
                    size="lg"
                    wrapperClass='m-1'
                    name="isModalWindows"
                    btn
                    id="btn-check-windows"
                    wrapperTag="span"
                    label="Windows"
                    checked={createFormData.isModalWindows}
                    onChange={(e) => handleCheckboxChange("isModalWindows", e.target.checked)}
                    onClick={() => {
                      setToggleOneModal(!toggleOneModal);
                      setTimeout(() => {
                        setToggleTwoModal(!toggleTwoModal);
                      }, 400);
                    }}
                  />
                  <MDBCheckbox
                    size="lg"
                    wrapperClass='m-1'
                    name="isModalMac"
                    btn
                    id="btn-check-mac"
                    wrapperTag="span"
                    label="Mac"
                    checked={createFormData.isModalMac}
                    onChange={(e) => handleCheckboxChange("isModalMac", e.target.checked)}
                    onClick={() => {
                      setToggleOneModal(!toggleOneModal);
                      setTimeout(() => {
                        setToggleTwoModal(!toggleTwoModal);
                      }, 400);
                    }}
                  />
                  <MDBCheckbox
                    size="lg"
                    wrapperClass='m-1'
                    name="isModalAndroid"
                    btn
                    id="btn-check-android"
                    wrapperTag="span"
                    label="Android"
                    checked={createFormData.isModalAndroid}
                    onChange={(e) => handleCheckboxChange("isModalAndroid", e.target.checked)}
                    onClick={() => {
                      setToggleOneModal(!toggleOneModal);
                      setTimeout(() => {
                        setToggleTwoModal(!toggleTwoModal);
                      }, 400);
                    }}
                  />
                  <MDBCheckbox
                    size="lg"
                    wrapperClass='m-1'
                    name="isModalIphone"
                    btn
                    id="btn-check-iphone"
                    wrapperTag="span"
                    label="iPhone"
                    checked={createFormData.isModalIphone}
                    onChange={(e) => handleCheckboxChange("isModalIphone", e.target.checked)}
                    onClick={() => {
                      setToggleOneModal(!toggleOneModal);
                      setTimeout(() => {
                        setToggleTwoModal(!toggleTwoModal);
                      }, 400);
                    }}
                  />
                  <MDBCheckbox
                    size="lg"
                    wrapperClass='m-1'
                    name="isModalWeb"
                    btn
                    id="btn-check-web"
                    wrapperTag="span"
                    label="Web"
                    checked={createFormData.isModalWeb}
                    onChange={(e) => handleCheckboxChange("isModalWeb", e.target.checked)}
                    onClick={() => {
                      setToggleOneModal(!toggleOneModal);
                      setTimeout(() => {
                        setToggleTwoModal(!toggleTwoModal);
                      }, 400);
                    }}
                  />
                </MDBContainer>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  type="button"
                  onClick={() => {
                    setToggleOneModal(!toggleOneModal);
                    setTimeout(() => {
                      setToggleTwoModal(!toggleTwoModal);
                    }, 400);
                  }}
                >
                  Neste
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
          {/* 
      -
      -
      -
      -
      setToggleTwoModal
      -
      -
      -
      -
       */}
        <MDBModal
          open={toggleTwoModal}
          setOpen={setToggleTwoModal}
          tabIndex="-1"
        >
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Lag et kort og informativt navn</MDBModalTitle>
                <MDBBtn
                  type="button"
                  className="btn-close"
                  color="none"
                  onClick={() => setToggleTwoModal(!toggleTwoModal)}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <MDBInput
                  className="m-2"
                  label="Navn"
                  type="text"
                  value={createFormData.modalName}
                  onChange={(e) =>
                    setCreateFormData({
                      ...createFormData,
                      modalName: e.target.value,
                    })
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevents form submission
                      setToggleTwoModal(!toggleTwoModal);
                      setTimeout(() => {
                        setToggleThreeModal(!toggleThreeModal);
                      }, 400);
                    }
                  }}
                />
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  type="button"
                  onClick={() => {
                    setToggleTwoModal(!toggleTwoModal);
                    setTimeout(() => {
                      setToggleOneModal(!toggleOneModal);
                    }, 400);
                  }}
                >
                  Tilbake
                </MDBBtn>

                <MDBBtn
                  type="button"
                  onClick={() => {
                    setToggleTwoModal(!toggleTwoModal);
                    setTimeout(() => {
                      setToggleThreeModal(!toggleThreeModal);
                    }, 400);
                  }}
                >
                  Neste
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        {/* 
      -
      -
      -
      -
      setToggleThreeModal
      -
      -
      -
      -
       */}
        <MDBModal
          open={toggleThreeModal}
          setOpen={setToggleThreeModal}
          tabIndex="-1"
        >
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Beskrivelse på problemet du løser</MDBModalTitle>
                <MDBBtn
                  type="button"
                  className="btn-close"
                  color="none"
                  onClick={() => setToggleThreeModal(!toggleThreeModal)}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div className="form_input-create">
                  <h4>Beskrivelse av brukerveiledning:</h4>
                  <MDBTextArea
                    label="beskrivelse"
                    className="m-2"
                    placeholder="Hvordan man laster ned Office-pakken. Denne pakken inneholder programmer som Excel, Word, PowerPoint og OneNote. Viktig å ha klart ved eksamen"
                    type="text"
                    value={createFormData.modalDescription}
                    onChange={(e) => {
                      if (e.target.value.length <= 200) {
                        setCreateFormData({
                          ...createFormData,
                          modalDescription: e.target.value,
                        });
                      }
                    }}
                  />
                  <div style={{ marginLeft: "1rem" }}>
                    {createFormData.modalDescription.length} / 200 Bokstaver
                  </div>
                </div>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  type="button"
                  onClick={() => {
                    setToggleThreeModal(!toggleThreeModal);
                    setTimeout(() => {
                      setToggleTwoModal(!toggleTwoModal);
                    }, 400);
                  }}
                >
                  Tilbake
                </MDBBtn>

                <MDBBtn
                  type="button"
                  onClick={() => {
                    setToggleThreeModal(!toggleThreeModal);
                    setTimeout(() => {
                      setToggle4Modal(!toggle4Modal);
                    }, 400);
                  }}
                >
                  Neste
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        {/* 
      -
      -
      -
      -
      setToggle4Modal
      -
      -
      -
      -
       */}
        <MDBModal open={toggle4Modal} setOpen={setToggle4Modal} tabIndex="-1">
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>
                  Forklar veiledningen med 3 ord (Print, Web, SafeQ)
                </MDBModalTitle>
                <MDBBtn
                  type="button"
                  className="btn-close"
                  color="none"
                  onClick={() => setToggle4Modal(!toggle4Modal)}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div className="form_input-create justify-content-center m-4 d-flex">
                  <MDBChipsInput
                    label="Tags"
                    className="m-2"
                    placeholder="Add a tag"
                    value={createFormData.modalTags} // Assuming this is now an array of strings
                    onAdd={(newTag) => {
                      setCreateFormData({
                        ...createFormData,
                        modalTags: [...createFormData.modalTags, newTag], // Add new tag to array
                      });
                    }}
                    onRemove={(indexToRemove) => {
                      setCreateFormData({
                        ...createFormData,
                        modalTags: createFormData.modalTags.filter(
                          (_, index) => index !== indexToRemove
                        ), // Remove tag by index
                      });
                    }}
                  />
                </div>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  type="button"
                  onClick={() => {
                    setToggle4Modal(!toggle4Modal);
                    setTimeout(() => {
                      setToggleThreeModal(!toggleThreeModal);
                    }, 400);
                  }}
                >
                  Tilbake
                </MDBBtn>

                <MDBBtn
                  type="button"
                  onClick={() => {
                    setToggle4Modal(!toggle4Modal);
                    setTimeout(() => {
                      setToggle5Modal(!toggle5Modal);
                    }, 400);
                  }}
                >
                  Neste
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        {/* 
      -
      -
      -
      -
      setToggle5Modal
      -
      -
      -
      -
       */}
        <MDBModal open={toggle5Modal} setOpen={setToggle5Modal} tabIndex="-1">
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Forside bilde til veiledningen.</MDBModalTitle>
                <MDBBtn
                  type="button"
                  className="btn-close"
                  color="none"
                  onClick={() => setToggle5Modal(!toggle5Modal)}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div className="form_input-create">
                  <div className="file-drop-area m-auto">
                    <span className="file-message">
                      <FaUpload className="upload_icon" />
                    </span>
                    <input
                      type="file"
                      onDrop={handleFileInputChange}
                      onChange={handleFileInputChange}
                    />
                  </div>
                  <p className="MaxFileSizeText mt-5">
                    Maksimal filstørrelse: 500 KB
                    <br />
                    Opplastet filstørrelse:{" "}
                    {createFormData.modalImage
                      ? (createFormData.modalImage.size / 1024).toFixed(2) +
                        " KB"
                      : "No file uploaded"}
                  </p>
                </div>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  type="button"
                  onClick={() => {
                    setToggle5Modal(!toggle5Modal);
                    setTimeout(() => {
                      setToggle4Modal(!toggle4Modal);
                    }, 400);
                  }}
                >
                  Tilbake
                </MDBBtn>

                <MDBBtn
                  type="button"
                  onClick={() => {
                    setToggle5Modal(!toggle5Modal);
                    setTimeout(() => {
                      setToggle6Modal(!toggle6Modal);
                    }, 400);
                  }}
                >
                  Neste
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        {/* 
      -
      -
      -
      -
      setToggle6Modal
      -
      -
      -
      -
       */}
        <MDBModal open={toggle6Modal} setOpen={setToggle6Modal} tabIndex="-1">
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Brukerveiledning bilder</MDBModalTitle>
                <MDBBtn
                  type="button"
                  className="btn-close"
                  color="none"
                  onClick={() => setToggle6Modal(!toggle6Modal)}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div className="form_input-create">
                  <div className="file-drop-area m-auto">
                    <span className="file-message">
                      <FaUpload className="upload_icon" />
                    </span>
                    <input
                      type="file"
                      multiple // Allow multiple file selection
                      onChange={handleContentFileInputChange}
                      onDrop={handleContentFileInputChange}
                    />
                  </div>
                </div>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  type="button"
                  onClick={() => {
                    setToggle6Modal(!toggle6Modal);
                    setTimeout(() => {
                      setToggle5Modal(!toggle5Modal);
                    }, 400);
                  }}
                >
                  Tilbake
                </MDBBtn>

                <MDBBtn
                  type="button"
                  onClick={() => {
                    setToggle6Modal(!toggle6Modal);
                    setTimeout(() => {
                      setToggle7Modal(!toggle7Modal);
                    }, 400);
                  }}
                >
                  Neste
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
        {/* 
      -
      -
      -
      -
      setToggle7Modal
      -
      -
      -
      -
       */}
        <MDBModal open={toggle7Modal} setOpen={setToggle7Modal} tabIndex="-1">
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>Publiser!</MDBModalTitle>
                <MDBBtn
                  type="button"
                  className="btn-close"
                  color="none"
                  onClick={() => setToggle7Modal(!toggle7Modal)}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>Nice!</MDBModalBody>
              <MDBModalFooter>
                <MDBBtn
                  type="button"
                  onClick={() => {
                    setToggle7Modal(!toggle7Modal);
                    setTimeout(() => {
                      setToggle6Modal(!toggle6Modal);
                    }, 400);
                  }}
                >
                  Tilbake
                </MDBBtn>
                <MDBBtn
                  type="submit"
                  onClick={() => {
                    setToggle7Modal(!toggle7Modal);
                  }}
                >
                  Fullfør
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </form>
    </MDBContainer>
  );
};

export default CreateModalForm;
