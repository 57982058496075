import React, { useState, useEffect } from "react";
import CreateModalForm from "./CreateModalForm"; // Import the CreateModalForm component
import EditModalForm from "./EditModalForm"; // Import the EditModalForm component
import AllModals from "./AllModals"; // Import the AllModals component
import "./ModalPanel.css";
import Navbar from "../roaldvgs/navbar/Navbar";
import { MDBBtn } from 'mdb-react-ui-kit'
import { useUserPermissions } from "../../context/UserPermissionsContext";


const ModalPanel = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  // Function to handle option selection
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const [theme, setTheme] = useState("light-mode");

  useEffect(() => {
    // Retrieve theme preference from localStorage
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
      const container = document.body; // or your top-level container
      container.classList.add(savedTheme);
    }
  }, []);

  const { hasPermission } = useUserPermissions();

  return (
    <div className="comp_wrapper">
      <Navbar />
      <div className="modalpanel_wrapper">
        <h3 className="modalpanel_header">Hva ønsker du å gjøre?</h3>
        <div className="NewEditOptions_wrapper">
          <div className="EditCreateMDBBtn_wrapper">
            {hasPermission("ModalCreate") && (
            <MDBBtn className="m-3" onClick={() => handleOptionSelect("new")}>
              Produser Brukerveiledning
            </MDBBtn>
            )}
            {hasPermission("ModalDelete") && (
            <MDBBtn className="m-3" onClick={() => handleOptionSelect("edit")}>
              Administrer Brukerveiledning
            </MDBBtn>
            )}
            {hasPermission("ModalCopy") && (
            <MDBBtn className="m-3" onClick={() => handleOptionSelect("copy")}>
              Kopier Brukerveiledning
            </MDBBtn>
            )}
          </div>
        </div>

        {/* Render the appropriate form based on the selected option */}
        {selectedOption === "new" && (
          <CreateModalForm className="CreateModalForm" />
        )}
        {selectedOption === "edit" && (
          <EditModalForm className="EditModalForm" />
        )}
        {selectedOption === "copy" && <AllModals className="AllModals" />}
      </div>
    </div>
  );
};

export default ModalPanel;
