import React, { useState, useEffect } from "react";
import axios from "axios";
import './ModalList.css'
import {
  MDBBadge,
  MDBBtn,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBContainer,
} from "mdb-react-ui-kit";

const ModalList = ({ onSelectModal }) => {
  const [modalList, setModalList] = useState([]);
  const selectedFaculty = localStorage.getItem("selectedFaculty"); // Get the selected faculty from localStorage

  useEffect(() => {
    axios
      .get(`/api/CRUD_Modal.php?faculty=${selectedFaculty}`)
      .then((response) => {
        console.log("Data received from the API:", response.data);
        setModalList(response.data);
      });
  }, [selectedFaculty]);

  const handleDeleteModal = async (modal) => {
    console.log("Logged modal: ", modal);
    const shouldDelete = window.confirm(
      "Er du sikker på at du ønsker å utføre denne handlingen?"
    );

    if (!shouldDelete) {
      return;
    }

    try {
      const response = await axios.delete(
        `/api/deleteModal.php?modalId=${modal}&faculty=${selectedFaculty}`
      );

      console.log(response.data);

      if (response.data.success) {
        setModalList(modalList.filter((m) => m !== modal));
        alert("Sletting velykket");
      } else {
        alert("Sletting feilet");
      }
    } catch (error) {
      console.error("Feil ved sletting av brukerveiledning:", error);
      alert("Sletting feilet:", error);
    }
  };

  // Toggle light/dark mode for table.

  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light";
    setTheme(savedTheme);
  }, []);

  return (
      <MDBContainer className="mt-4">
      <h3>Slett en brukerveiledning på {selectedFaculty}</h3>
      <MDBTable
        responsive
        align="middle"
        className="bg-transparent"
      >
        <MDBTableHead>
          <tr>
            <th scope="col">Navn</th>
            <th scope="col">Enhet</th>
            <th scope="col">Handling</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {modalList.map((modal) => (
            <tr key={modal.modalName}>
              <td>
                <div className="d-flex align-items-center">
                  <h5 className="modalName">{modal.modalName}</h5>
                </div>
              </td>
              <td>
                {modal.modalMac === 1 && <span><MDBBadge pill className="mx-1" color="danger">MAC</MDBBadge></span>}
                {modal.modalWindows === 1 && <span><MDBBadge pill className="mx-1" color="success">WINDOWS</MDBBadge> </span>}
                {modal.modalAndroid === 1 && <span><MDBBadge pill className="mx-1" color="primary">ANDROID</MDBBadge> </span>}
                {modal.modalIphone === 1 && <span><MDBBadge pill className="mx-1" color="warning">IPHONE</MDBBadge> </span>}
                {modal.modalWeb === 1 && <span><MDBBadge pill className="mx-1" color="info">WEB</MDBBadge></span>}
              </td>
              <td>
                <MDBBtn
                  color="link"
                  rounded
                  size="sm"
                  className="btn-delete"
                  onClick={() => handleDeleteModal(modal.modalId)}
                >
                  Delete
                </MDBBtn>
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
      </MDBContainer>
  );
};

export default ModalList;
