import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserPermissions } from '../../context/UserPermissionsContext';
import Success from './Success';
import Cancel from './Cancel';

const Portal = () => {
    const { isAuthenticated } = useUserPermissions();
    const history = useHistory();
    const [sessionId, setSessionId] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (!isAuthenticated) {
            history.push('/Login');
        }

        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setSuccess(true);
            setSessionId(query.get('session_id'));
        }

        if (query.get('canceled')) {
            setSuccess(false);
            setSessionId('');
        }
    }, [isAuthenticated, history]);

    if (success && sessionId) {
        return <Success sessionId={sessionId} />;
    } else {
        return <Cancel />;
    }
};

export default Portal;
