import React, { useState } from "react";
import "./Feedback.css";
import "isomorphic-fetch";


function Feedback() {
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(null);
  const [bad] = useState(null);
  const [good] = useState(null);
  const [activeButton, setActiveButton] = useState("good");
  const [firstName, setName] = useState("");


const handleSubmit = (event) => {
  event.preventDefault();

  // Get the faculties value from the URL
  const faculties = window.location.pathname.split("/")[1];

  // Create a data object to send to the server
  const data = { feedback, rating, firstName, faculties };

  // Send the data to the server using fetch
  fetch("/api/feedback.php", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("Failed to send feedback");
      }
    })
    .then((json) => {
      console.log(json);
      if (json.error) {
        throw new Error(json.error);
      } else {
        setFeedback("");
        setRating("");
        setName("");
      }
    });
};





  return (
    <div className="viken__feedback-wrapper section__padding" id="feedback">
      <div className="viken__feedback">
        <div className="viken__feedback-header">
          <p>Funnet en feil, eller noe som mangler?</p>
          <h5>Send tilbakemelding</h5>
        </div>
        <div className="viken__feedback-form">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Navn"
              value={firstName}
              onChange={(e) => setName(e.target.value)}
              required
              minLength="3"
            />
            <textarea
              value={feedback}
              onChange={(event) => setFeedback(event.target.value)}
              placeholder="Kom gjerne med tilbakemeldinger! ❤️"
              cols={64}
              rows={12}
              maxLength="500"
              minLength="3"
              required
            />
            <div>
              <p className="rating-text">
                velg en smiley <br /> (bra eller dårlig)
              </p>
              <button
                value={bad}
                type="button"
                className={`feedback-rating ${activeButton === "bad" ? "active" : ""
                  }`}
                onClick={() => {
                  setRating("bad");
                  setActiveButton("bad");
                }}
              >
                <span role="img" aria-label="sad smiley">
                  😩
                </span>
              </button>
              <button
                value={good}
                type="button"
                className={`feedback-rating ${activeButton === "good" ? "active" : ""
                  }`}
                onClick={() => {
                  setRating("good");
                  setActiveButton("good");
                }}
              >
                <span role="img" aria-label="happy smiley">
                  😎
                </span>
              </button>
            </div>
            <button className="feedback_submit" type="submit">
              Send inn
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Feedback;