import React, { useState, useEffect } from "react";
import "./AdminChangelog.css";

import { MDBBtn, MDBInput, MDBChipsInput, MDBIcon } from "mdb-react-ui-kit";

import { useUserPermissions } from "../../context/UserPermissionsContext";
import Navbar from "../roaldvgs/navbar/Navbar";

const AdminChangelog = () => {
  const [title, setTitle] = useState("");
  const [changes, setChanges] = useState([]);
  const [message, setMessage] = useState(""); // State to store success or error message
  const [theme, setTheme] = useState("light-mode");

  useEffect(() => {
    // Retrieve theme preference from localStorage
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
      const container = document.body; // or your top-level container
      container.classList.add(savedTheme);
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === "light-mode" ? "dark-mode" : "light-mode";
    setTheme(newTheme);
    document.body.classList.remove(theme);
    document.body.classList.add(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare the data to send to the PHP API
    const changesString = `"${changes.join(", ")}"`; // Wrap changes in double quotes and convert to comma-separated string
    const formData = new FormData();
    formData.append("title", title);
    formData.append("changes", changesString);

    // Send a POST request to the PHP API
    fetch("/api/changelog.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the success response from the PHP API
        setMessage(data.message); // Set the success message
        // You can update your changelogs state here if needed
      })
      .catch((error) => {
        // Handle errors
        setMessage("Error: There was a problem with the fetch operation.");
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  // Permission
  const { hasPermission } = useUserPermissions();

  if (!hasPermission) {
    return <NoAccess />;
  }

  return (
    <div className="create_changelog-wrapper section__padding">
      <Navbar />
      <h4 className="mb-4">Create Changelog</h4>
      {hasPermission("Developer") && (
        <form onSubmit={handleSubmit}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          enctype="multipart/form-data"
          preventDefault={true}>
          <div className="changelog_input-form mb-3">
            <MDBInput type="text" label="Title" value={title} onChange={handleTitleChange} required />
          </div>
          <div className="changelog_input-form mb-3">
            <MDBChipsInput
              label="Describe changes"
              value={changes}
              onAdd={(newChange) => {
                setChanges([...changes, newChange]); // Add new change to array
              }}
              onRemove={(indexToRemove) => {
                setChanges(changes.filter((_, index) => index !== indexToRemove)); // Remove change by index
              }}
              placeholder="Enter changes and press enter"
            />
          </div>
          <div className="changelog_input-form">
            <MDBBtn className="btn btn-primary w-25" type="submit">
              Create Changelog
            </MDBBtn>
          </div>
        </form>
      )}
      {message && (
        <p className={message.startsWith("Error") ? "error-message" : "success-message"}>
          {message}
        </p>
      )}
    </div>
  );
};

export default AdminChangelog;
