import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./Admin.css";
import axios from "axios";

import { MDBContainer } from "mdb-react-ui-kit";
import Hero from "./roaldvgs/hero/Hero";
import ClickCount from "./roaldvgs/clickStats/ClickStats";
import SideNav from "./roaldvgs/navbar/SideNav";

const Admin = (props) => {
  const userRoleData = JSON.parse(localStorage.getItem("roleData") || "{}");
  const selectedFaculty = localStorage.getItem("selectedFaculty");
  const [userHasAccess, setUserHasAccess] = useState(false);
  const [theme, setTheme] = useState();

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light";
    setTheme(savedTheme);
    switchCssFile(savedTheme);
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    switchCssFile(newTheme);
  };

  const switchCssFile = (theme) => {
    if (theme === "dark") {
      import("mdb-react-ui-kit/dist/css/mdb.dark.min.css");
    } else {
      import("mdb-react-ui-kit/dist/css/mdb.min.css");
    }
  };
  useEffect(() => {
    const firebaseUserId = localStorage.getItem("firebaseUserId");
    if (!firebaseUserId) {
      console.error("No Firebase User ID found.");
      window.location.href = "/Login";
      return;
    }

const fetchUserData = async () => {
  try {
    const response = await axios.post("/api/fetch_user_data.php", {
      firebaseUserId,
    });
    const userData = response.data;
    if (userData && userData.user) {
      // Convert userFaculties object to array of faculty names
      const userFaculties = Object.values(userData.user.faculties);
      const selectedFacultyParam = props.match.params.faculty;
      if (userFaculties && userFaculties.includes(selectedFacultyParam)) {
        setUserHasAccess(true);
      } else {
        console.error("User does not have access to this faculty.");
        setTimeout(() => {
          window.location.href = "/Login";
        }, 3000); // Give user time to read the message
      }
    }
  } catch (error) {
    console.error("Error fetching user data:", error.message);
    setTimeout(() => {
      alert("ingen tilgang");
      window.location.href = "/Login";
    }, 3000); // Redirect after a short delay
  }
};


    fetchUserData();
  }, [props.match.params.faculty]);

  const renderFacultyComponents = (facultyName) => {
    return (
      <MDBContainer fluid className="viken__admin-wrapper p-0">
        <SideNav />
          <Hero />
              <ClickCount />
      </MDBContainer>
    );
  };

  return <div>{renderFacultyComponents(selectedFaculty)}</div>;
};

export default withRouter(Admin);
