import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Configure.css";
import Navbar from "../../admin/roaldvgs/navbar/Navbar";
import {
  MDBBtn,
  MDBContainer,
  MDBInput,
  MDBRange,
  MDBAlert,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBCheckbox,
  MDBSelect,
  MDBPopconfirm,
  MDBPopconfirmMessage,
  MDBBadge,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import SuccessAlert from "../components/successAlert/SuccessAlert";
import FailAlert from "../components/failAlert/FailAlert";
import { useUserPermissions } from "../../context/UserPermissionsContext";


const Configure = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  // Handle permissions
  const { hasPermission } = useUserPermissions();

  function fixedBody() {
    document.body.classList.remove("fixed-position");
  }
  fixedBody();

  const [theme, setTheme] = useState("light-mode");

  useEffect(() => {
    // Retrieve theme preference from localStorage
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
      const container = document.body; // or your top-level container
      container.classList.add(savedTheme);
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent(""); // Clear the modal content
  };

  const [newData, setNewData] = useState("");
  const handleSave = () => {
    const facultyName = localStorage.getItem("selectedFaculty");

    if (!facultyName) {
      console.error("Invalid facultyName in Local Storage!");
      return;
    }

    if (newData.length <= 1 && email.length <= 1) {
      console.log("Please enter more than one character to update.");
      return;
    }

    const requestData = {
      facultyName: facultyName,
    };

    if (newData.length > 1) {
      requestData.skolenavn = newData;
    }

    if (email.length > 1) {
      requestData.email = email;
    }

    if (latestNews.length > 1) {
      requestData.latestNews = latestNews;
    }

    if (phone.length > 1) {
      requestData.phone = phone;
    }

    axios
      .post("/api/update_data.php", requestData)
      .then((response) => {
        if (response.data.success) {
          setTimeout(() => {
            setIsSuccess(true); // Set success state to true
          }, 5000);
        } else {
          setIsFailed(true); // Set success state to trues
        }
      })
      .catch((error) => {
        console.error("Error updating Data:", error);
      });
  };

  // Fetch all faculty data from databsae

  useEffect(() => {
    const facultyName = localStorage.getItem("selectedFaculty");

    if (facultyName) {
      // Fetch data
      axios
        .get(`/api/get_data.php?facultyName=${facultyName}`)
        .then((response) => {
          setData(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching DATA:", error);
        });

      // Fetch email
      axios
        .get(`/api/get_email.php?facultyName=${facultyName}`)
        .then((response) => {
          setEmail(response.data.email);
        })
        .catch((error) => {
          console.error("Error fetching Email:", error);
        });

      // Fetch phone
      axios
        .get(`/api/get_phone.php?facultyName=${facultyName}`)
        .then((response) => {
          setPhone(response.data.phone);
        })
        .catch((error) => {
          console.error("Error fetching phone:", error);
        });
    }
  }, []);

  // END OF = Fetch all faculty data from database


  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const [announcementText, setAnnouncementText] = useState("");
  const [announcementTitle, setAnnouncementTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [isAnnouncementActive, setIsAnnouncementActive] = useState(false);
  const [color, setColor] = useState("primary");
  const [announcements, setAnnouncements] = useState([]);
  const facultyName = localStorage.getItem("selectedFaculty");

  const handleColorChange = (value) => {
    setColor(value);
  };

  const postAnnouncement = async () => {
    // Show confirmation dialog to the user
    const confirmPost = window.confirm(
      "Er du sikker på at du ønsker å lage denne teksten?"
    );

    // If the user clicks "Cancel", exit the function early
    if (!confirmPost) return;

    const facultyName = localStorage.getItem("selectedFaculty");

    try {
      const response = await axios.post("/api/post_announcement.php", {
        facultyName,
        text: announcementText,
        title: announcementTitle,
        active: isAnnouncementActive ? 1 : 0,
        color,
      });

      if (response.data.success) {
        console.log("Announcement posted successfully");
        // Reset form or handle success (e.g., showing success alert or clearing form)
        setAnnouncementText("");
        setAnnouncementTitle("");
        setIsAnnouncementActive(false); // Assuming this is a state to track if the announcement is active
        setColor(""); // Assuming you have a state for color
        setIsSuccess(true); // Assuming you have a state to handle success feedback
      } else {
        console.error("Failed to post announcement");
        setIsFailed(true); // Assuming you have a state to handle failure feedback
      }
    } catch (error) {
      console.error("Error posting announcement:", error);
      setIsFailed(true); // Assuming you have a state to handle failure feedback
    }
  };

  const deleteAnnouncement = async (id) => {
    try {
      const response = await axios.delete(`/api/delete_announcement.php`, {
        data: { id },
      });
      if (response.data.success) {
        console.log("Announcement deleted successfully.");
        setIsSuccess(true);
        // Remove the announcement from the state or re-fetch the list
        setAnnouncements((prev) => prev.filter((ann) => ann.id !== id));
      } else {
        console.error(
          "Failed to delete the announcement:",
          response.data.message
        );
        setIsFailed(true);
      }
    } catch (error) {
      console.error("Error deleting announcement:", error);
      setIsFailed(true);
    }
  };




  useEffect(() => {
    // Fetch announcements
  }, [facultyName]);

  const setActiveAnnouncement = async (announcement) => {
    try {
      const response = await axios.post("/api/update_announcement.php", {
        facultyName: localStorage.getItem("selectedFaculty"),
        id: announcement.id, // Ensure this is just the announcement ID, not an object
        active: 1, // Explicitly set 'active' to 1 when activating an announcement
        color: announcement.color,
        text: announcement.text,
        title: announcement.title,
      });

      if (response.data.success) {
        console.log("Announcement set to active successfully.");
        setIsSuccess(true);
        // Optionally, refresh announcement list or update UI accordingly
      } else {
        console.error(
          "Failed to set announcement as active:",
          response.data.message
        );
        setIsFailed(true);
      }
    } catch (error) {
      console.error("Error setting announcement as active:", error);
      setIsFailed(true);
    }
  };





  const [formData, setFormData] = useState({
    facultyBackground: "",
    refreshTimer: 0,
    lightMode: 1,
  });


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Convert refreshTimer from seconds to milliseconds
    const refreshTimerMilliseconds = formData.refreshTimer * 1000;

    // Form data sent in the request
    const requestData = {
      facultyName,
      facultyBackground: formData.facultyBackground,
      refreshTimer: refreshTimerMilliseconds, // Use the converted value
      lightMode: formData.lightMode,
    };

    // Make a POST request using Axios
    axios
      .post("/api/facultySettingsApi.php", requestData)
      .then((response) => {
        // Handle the response from the backend, e.g., show success or error messages to the user
        console.log(response.data);

        // Reset the form or perform any other necessary actions.
        setFormData({
          facultyBackground: "",
          refreshTimer: 0,
          lightMode: 1,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [refreshTimerValue, setRefreshTimerValue] = useState(0);

  useEffect(() => {
    // Fetch the values from backend API
    axios
      .get(`/api/getFacultySettings.php?facultyName=${facultyName}`)
      .then((response) => {
        const fetchedData = response.data;
        // Update the formData state with the fetched values
        setFormData({
          facultyBackground: fetchedData.facultyBackground,
          refreshTimer: fetchedData.refreshTimer,
        });

        // Set the refreshTimer value in the state
        setRefreshTimerValue(fetchedData.refreshTimer / 1000);
      })
      .catch((error) => {
        console.error("Error fetching faculty settings:", error);
      });
  }, [facultyName]);


  // Fetch the announcement buttons frmo database:

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get(
          `/api/fetch_announcements.php?facultyName=${facultyName}`
        );
        if (response.data.success) {
          setAnnouncements(response.data.announcements);
        } else {
          console.error("Failed to fetch announcements");
        }
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    if (facultyName) {
      fetchAnnouncements();
    }
  }, [facultyName]);

  return (
    <MDBContainer fluid>
      <Navbar />
      {isSuccess && <SuccessAlert />}
      {isFailed && <FailAlert />}
      {hasPermission("FacultyCreate") && (
        <MDBContainer className="d-flex flex-column flex-wrap w-100">
          <div className=" p-2">
            <h2 className="my-3" color="primary">
              Konfigurer portal for: {data}
            </h2>
          </div>
          <MDBContainer className="ny-4">
            <div className="glass-card p-4">
              <MDBTypography tag="h3">Lagrede tekster på {facultyName}:</MDBTypography>
              <section className="w-100 p-2">
                <div className="d-flex mb-2 mx-2 flex-wrap">
                  <MDBTable align="middle" responsive className="bg-transparent">
                    <MDBTableHead>
                      <tr>
                        <th scope="col">Tittel</th>
                        <th scope="col">Tekst</th>
                        <th scope="col">Status</th>
                        <th scope="col">Handling</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {announcements.map((announcement) => (
                        <tr key={announcement.id}>
                          <td>{announcement.title}</td>
                          <td>{announcement.text}</td>
                          <td>
                            <MDBBadge
                              className="p-2"
                              color={announcement.active ? "success" : "danger"}
                              pill
                            >
                              {announcement.active ? "Aktiv" : "Inaktiv"}
                            </MDBBadge>
                          </td>
                          <td>
                            <MDBPopconfirm
                              btnClassName={`m-2 btn-link text-white bg-${announcement.color}`}
                              btnChildren="Aktiver"
                              btnColor={announcement.color}
                              onConfirm={() =>
                                setActiveAnnouncement(announcement)
                              }
                              cancelBtnText="Avbryt"
                              confirmBtnText="Bekreft"
                              className="w-25"
                            >
                              <MDBPopconfirmMessage>
                                <p>
                                  <strong>
                                    Er du sikker på at du ønsker å aktivere
                                    denne teksten?
                                  </strong>
                                </p>
                                <p>Tittel: {announcement.title}</p>
                                <p>
                                  Tekst (Dette ser bruker): {announcement.text}
                                </p>
                              </MDBPopconfirmMessage>
                            </MDBPopconfirm>
                            <MDBPopconfirm
                              btnClassName="btn-link m-2 bg-secondary text-dark"
                              btnChildren="Slett"
                              onConfirm={() =>
                                deleteAnnouncement(announcement.id)
                              }
                              cancelBtnText="Avbryt"
                              confirmBtnText="Bekreft"
                            >
                              <MDBPopconfirmMessage>
                                Er du sikker på at du ønsker å slette denne teksten?
                              </MDBPopconfirmMessage>
                            </MDBPopconfirm>
                          </td>
                        </tr>
                      ))}
                    </MDBTableBody>
                  </MDBTable>
                </div>
              </section>
            </div>
          </MDBContainer>
          <MDBContainer className="flex-column flex-wrap d-flex mx-2 my-4">
            <div className="glass-card p-4">
              <MDBTypography tag="h3">
                Lag en ny tekst
              </MDBTypography>
              <MDBRow className="w-100">
                <MDBCol md="4" className="my-2">
                  <MDBInput
                    className="my-5"
                    label="Tittel"
                    type="text"
                    rows="4"
                    value={announcementTitle}
                    onChange={(e) => setAnnouncementTitle(e.target.value)}
                  />
                  <MDBInput
                    label="Tekst som presanteres på forsiden"
                    type="textarea"
                    className="my-5"
                    rows="4"
                    value={announcementText}
                    onChange={(e) => setAnnouncementText(e.target.value)}
                  />
                  <div className="d-flex flex-column mt-5 mb-5 w-100">
                    <MDBSelect
                      label="Velg en farge"
                      selected={color}
                      onValueChange={handleColorChange}
                      data={[
                        {
                          text: "Danger",
                          secondaryText: "Lyse rød farge",
                          value: "danger",
                        },
                        {
                          text: "Info",
                          secondaryText: "Turkis",
                          value: "info",
                        },
                        {
                          text: "Success",
                          secondaryText: "lyse grønn",
                          value: "success",
                        },
                        {
                          text: "Warning",
                          secondaryText: "Lyse gul",
                          value: "warning",
                        },
                        {
                          text: "Primary",
                          secondaryText: "Blå / Lilla",
                          value: "primary",
                        },
                      ]}
                    />
                  </div>
                  <div className="d-flex align-items-center mt-5 mb-2 w-100">
                    <MDBCheckbox
                      label="Aktiver med en gang?"
                      id="announcement-active"
                      checked={isAnnouncementActive}
                      onChange={(e) => setIsAnnouncementActive(e.target.checked)}
                    />
                  </div>
                </MDBCol>
                <MDBCol md="8" className="my-2">
                  <section class="w-100 d-flex flex-wrap row p-4 text-center pb-4">
                      <MDBCol md="3" className="my-2 mx-1">
                        <div class="square text-center d-flex justify-content-center align-items-center bg-primary shadow-1-strong p-2">
                          Primary
                        </div>
                      </MDBCol>
                      <MDBCol md="3" className="my-2 mx-1">
                        <div class="square text-center d-flex justify-content-center align-items-center bg-success shadow-1-strong p-2">
                          Success
                        </div>
                      </MDBCol>
                      <MDBCol md="3" className="my-2 mx-1">
                        <div class="square text-center d-flex justify-content-center align-items-center bg-danger shadow-1-strong p-2">
                          Danger
                        </div>
                      </MDBCol>
                      <MDBCol md="3" className="my-2 mx-1">
                        <div class="square text-center d-flex justify-content-center align-items-center bg-warning shadow-1-strong p-2">
                          Warning
                        </div>
                      </MDBCol>
                      <MDBCol md="3" className="my-2 mx-1">
                        <div class="square text-center d-flex justify-content-center align-items-center bg-info shadow-1-strong p-2">
                          Info
                        </div>
                      </MDBCol>
                  </section>
                </MDBCol>
              </MDBRow>
              <MDBCol md="4" className="mx-2 my-1">
                <MDBBtn
                  onClick={postAnnouncement}
                  className="mb-4 mt-2"
                  color="primary"
                >
                  Lagre
                </MDBBtn>
              </MDBCol>
            </div>
          </MDBContainer>
          <MDBContainer className="mb-4">
            <div className="glass-card p-4">
              <MDBTypography tag="h3">
                Generelle innstillinger
              </MDBTypography>
              <div className="d-flex flex-wrap">
                <MDBCol md="4" className="my-3 mx-2">
                  <MDBTypography tag="h5">
                    <strong>Skolenavn</strong>
                  </MDBTypography>
                  <MDBInput
                    label="Skolenavn"
                    className="my-3"
                    type="text"
                    placeholder={data}
                    onChange={(e) => setNewData(e.target.value)}
                  />
                </MDBCol>
                <MDBCol md="4" className="my-3 mx-2">
                  <MDBTypography tag="h5">
                    <strong>Endre IT- Epost</strong>
                  </MDBTypography>
                  <MDBInput
                    label="IT E-post"
                    className="my-3"
                    type="email"
                    placeholder={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </MDBCol>
                <MDBCol md="4" className="my-3 mx-2">
                  <MDBTypography tag="h5">
                    <strong>Telefonnummer til IT</strong>
                  </MDBTypography>
                  <MDBInput
                    label="Telefon - IT"
                    type="tel"
                    className="my-3"
                    placeholder="IT telefonnummer"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </MDBCol>
                <MDBCol md="4" className="my-3 mb-4 w-100">
                  <form onSubmit={handleSubmit} className="w-100">
                    <MDBCol md="6" className="my-3 mx-2">
                      <MDBTypography tag="h5">
                        <strong>Bakgrunnsbilde URL (16x9) - Synlig for kunder</strong>
                      </MDBTypography>
                      <MDBInput
                        type="url"
                        label="Bilde URL"
                        id="facultyBackground"
                        name="facultyBackground"
                        value={formData.facultyBackground}
                        onChange={handleChange}
                        className="my-3"
                      />
                      <p className="mt-2 p-1">
                        Nåværende verdi: <br />
                        {formData.facultyBackground}
                      </p>
                    </MDBCol>
                    <MDBCol md="6" className="mx-2 my-3">
                      <MDBRange
                        defaultValue={refreshTimerValue}
                        onChange={handleChange}
                        label="Refresh timer (målt i sekunder)"
                        id="refreshTimer"
                        name="refreshTimer"
                        min="180"
                        max="480"
                      />
                      <p className="mt-2 p-1">
                        Nåværende verdi: <br />
                        {refreshTimerValue}s
                      </p>
                    </MDBCol>
                    <MDBCol md="6" className="mx-2 my-3">
                      <MDBBtn
                        className="btn m-2"
                        style={{
                          width: "85px"
                        }}
                        color="primary"
                        type="submit"
                        onClick={handleSave}
                      >
                        Lagre
                      </MDBBtn>
                    </MDBCol>
                  </form>
                </MDBCol>
              </div>
            </div>
          </MDBContainer>
        </MDBContainer>
      )}

    </MDBContainer>
  );
};

export default Configure;
